<template>
  <LiefengContent @backClick="backClick" :isBack='$route.query.isLineHome || $route.query.page ? true : false'>
      <template v-slot:title>网上家园居民补充住址信息
        <span class="eye" @click.stop="changeEye">
            <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
        </span>
          <!-- <Button type="error" v-if="$route.query.isLineHome || $route.query.page" @click="$router.push(`/linehomemanage?isLineHome=${$route.query.isLineHome}&page=${$route.query.page}`)">返回</Button> -->
      </template>
      <template v-slot:toolsbarLeft>
      <!-- <LiefengTree
            ref="mychild"
            treeID="mychild"
            fixedContentID="contentarea"
            :fixedmenu="true"
            @handleData="getList"
            style="width: 200px"
        ></LiefengTree> -->
        </template>
      <template v-slot:toolsbarRight>
            <Form  :label-colon="true" :inline="true" class="search">
                <FormItem>
                <Input
                    :maxlength="20"
                    v-model.trim="searchData.name"
                    placeholder="请输入姓名"
                    style="margin-right:10px;width: 150px"
                />
                </FormItem>
                <FormItem>
                    <Input
                        :maxlength="20"
                        style="margin-right:10px;width: 150px"
                        v-model.trim="searchData.mobile"
                        placeholder="请输入手机号"
                    />
                    </FormItem>
                <FormItem>
                    <Select transfer v-model="searchData.contact" style="width:150px">
                        <Option v-for="(item,index) in concactList" :value="item.value" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <Button type="primary" style="margin: 0 4px" icon="ios-search" @click="getList">搜索</Button>
                <Button type="success" style="margin: 0 4px" icon="ios-refresh" @click="rest">重置</Button>
            </Form>
      </template>
      <template v-slot:contentArea>
          <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize" />
         <LiefengModal :fullScreen="false" :value="modal.status" title="修改信息" @input="modalData" class="edit">
             <template v-slot:contentarea>
                 <supp-edit :editRow="editRow" :oldAddr="oldAddr"></supp-edit>
             </template>
             <template v-slot:toolsbar>
                <Button type="info" @click="modalData(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="saveEdit">确定</Button>
            </template>
         </LiefengModal>
      </template>

  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import SuppEdit from './childrens/suppEdit.vue';
import LiefengTree from "./childrens/LiefengTree.vue";
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,SuppEdit,LiefengTree},
    data(){
        return{
            hasSensitive:true,
            // loading:false,
            treeParams: {
                // inviterFlag: 1, //1 居民 2 成员
                // status: '', //必填
                roadCode: "",
                projectCode: "",
                communityCode: "", //必填
                pageSize: 20,
                page: 1,
                name: "",
                mobile: "",
                contact:'',
                oemCode: "", //必填  缓存拿
            },

            tableColumns:[
                {
                    title: '姓名',
                    key: 'name',
                    minWidth: 100,
                    align: "center"
                },
                {
                    title: '昵称',
                    key: 'nickname',
                    minWidth: 100,
                    align: "center"
                },
                {
                    title: '联系电话',
                    key: 'phone',
                    minWidth: 100,
                    align: "center"
                },
                {
                    title: '与住址关系',
                    // key: 'relationship',
                    minWidth: 100,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},this.houseOptions.map(item=>{
                            if(item.dictKey == params.row.userType){
                                return item.dictValue
                            }
                        }))
                    }
                },
                {
                    title: '区',
                    minWidth: 100,
                    align: "center",
                    key:'zoneName'
                },
                {
                    title: '街道',
                    minWidth: 100,
                    align: "center",
                    key:'streetName'
                },
                {
                    title: '社区',
                    minWidth: 100,
                    align: "center",
                    key:'orgName'
                },
                {
                    title: '所在详细区域',
                    minWidth: 100,
                    align: "center",
                    key:'addr'
                },
                {
                    title: '是否家庭户代表',
                    minWidth: 100,
                    align: "center",
                     render: (h, params) => {
                        return h('div',{},params.row.contact == 1?'是':'否')
                    }
                },
                // {
                //     title: '是否关联社区架构',
                //     minWidth: 100,
                //     align: "center",
                //      render: (h, params) => {
                //         return h('div',{},params.row.contact == 1?'是':'否')
                //     }
                // },
                   {
                        title: '操作',
                        key: 'action',
                        fixed: 'right',
                        align: "center",
                        minWidth: 250,
                        render: (h, params) => {
                            let data = params.row
                            return h('div', [
                            h('Button', {
                                props: {
                                    type: 'success',
                                    size: 'small',
                                },
                                style: {
                                    marginRight: '10px',
                                    // display:params.row.status === '1'?'none':'inline-block'
                                },
                                on: {
                                    click: () => {
                                        this.getDetail(params.row.familyMemberId)
                                 
                                    }
                                }
                            }, '修改'),
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small',
                                },
                                // style: {
                                //     display:params.row.status === '1'?'none':'inline-block'
                                // },
                                on: {
                                    click: () => {
                                        this.$Modal.confirm({
                                            title: "温馨提示",
                                            content: "<p>确认删除数据</p>",
                                            cancelText: "取消",
                                            okText: "确认",
                                            onOk: () => {
                                                this.$post("/syaa/pc/sy/user/online/home/delFamilyMember", {
                                                    familyMemberId: params.row.familyMemberId
                                                })
                                                .then(res => {
                                                    if (res.code === "200") {
                                                        this.$Message.success({content:"删除成功",background:true});
                                                        this.page = this.currentPage
                                                        this.getList();
                                                    }else{
                                                        this.$Message.error({content:"删除失败",background:true});
                                                    }
                                                })
                                                .catch(err => console.log(err));
                                            }
                                            });
                                    }
                                }
                            }, '删除'),
                            ]);
                        }
                        }
            ],
            tableData:[],
            loading:false,
            page:1,
            total:0,
            pageSize:20,
            currentPage:1,
            searchData:{
                name:'',
                mobile:'',
                contact:''
            },
            modal:{
                status:false,
            },
            editRow:{},
            concactList:[
                {value:'',label:'全部'},
                {value:'1',label:'家庭户代表'},
                {value:'2',label:'非家庭户代表'},
            ],
            houseOptions:[],
            oldAddr:'',
        }
    },
    methods:{
        backClick(){
            this.$router.push(`/linehomemanage?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
        },
        // 修改保存的事件
        saveEdit(){
            if(!this.editRow.name || this.editRow.name == ''){
                this.$Message.warning({
                    content:'姓名不能为空',
                    background:true
                })
                return
            }
            if(!this.editRow.houseCode || this.editRow.houseCode == ''){
                this.$Message.warning({
                    content:'请选择现住地址到房号',
                    background:true
                })
                return
            }
            this.update(this.editRow)
        },
        // 分页部分
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        modalData(status){
            this.modal.status = status
        },

        //点击了重置按钮
        rest(){
            this.searchData = {
                name:'',
                mobile:'',
                contact:''
            }
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        // 接口部分
        // 获取分页接口
        getList(){
            this.loading = true
            this.$get('/syaa/pc/sy/user/online/home/selectSyFamilyMemberAdmittedPage',{
                operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode:this.$route.query.communityCode,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                page:this.page,
                pageSize:this.pageSize,
                // status:'',
                ...this.searchData,
                hasSensitive:this.hasSensitive
               
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }

            })
        },
        changeEye(){
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },

        // getList(data) {
        //     console.log(data);
        //     this.loading = true;
        //     if (data) {
        //         //第一次进来页面就会执行
        //         if (data.communityCode) {
        //         this.searchData.contact = "";
        //         this.searchData.name = "";
        //         this.searchData.mobile = "";
        //         this.orgCode = parent.vue.loginInfo.userinfo.orgCode
        //         this.tableData = [];
        //         this.treeParams = {
        //             ...data,
        //             communityCode:data.communityCode,
        //             contact: this.searchData.status, //必填
        //             name: this.searchData.name,
        //             mobile: this.searchData.mobile,
        //             oemCode: parent.vue.oemInfo.oemCode,
        //             page: data.page,
        //             pageSize: data.pageSize,
        //         };
        //         this.curPage = data.page;
        //         this.pageSize = data.pageSize;
        //         }
        //         if (data.page) {
        //         //切换页面时
        //         this.curPage = data.page;
        //         this.pageSize = data.pageSize;
        //         this.treeParams.page = data.page;
        //         this.treeParams.pageSize = data.pageSize;
        //         this.treeParams.name = this.searchData.name;
        //         this.treeParams.mobile = this.searchData.mobile;
        //         this.treeParams.contact = this.searchData.contact;
        //         }
        //         if (data.cancelable) {
        //         //点击查询时  查询返回第一页的数据
        //         this.treeParams.page = 1;
        //         this.treeParams.name = this.searchData.name;
        //         this.treeParams.mobile = this.searchData.mobile;
        //         this.treeParams.contact = this.searchData.contact;
        //         this.curPage = 1;
        //         }
        //     } else {
        //         //重置的时候执行
        //         this.treeParams = {
        //         communityCode: this.treeParams.communityCode,
        //         roadCode: this.treeParams.roadCode,
        //         name: this.searchData.name,
        //         mobile: this.searchData.mobile,
        //         detailAddr: this.searchData.detailAddr,
        //         contact: this.searchData.contact,
        //         oemCode: parent.vue.oemInfo.oemCode,
        //         page: this.curPage,
        //         pageSize: this.pageSize,
        //         };
        //     }
        //     console.log(parent.vue.loginInfo.userinfo)
        //     console.log(this.treeParams);
        //     //请求页面表格数据
        //     this.$get(
        //         "/syaa/pc/sy/user/online/home/selectSyFamilyMemberAdmittedPage",{
        //         ...this.treeParams,
        //         orgCode:this.orgCode,
        //         operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId
        //         }
        //     )
        //         .then((res) => {
        //         if (res.code === "200") {
        //             this.tableData = res.dataList;
        //             this.total = res.maxCount;
        //             this.curPage = res.currentPage;
        //             this.treeParams.name = "";
        //             this.treeParams.mobile = "";
        //             this.loading = false;
        //             this.selectQuery = false;
        //             this.searchData.name = '';
        //             this.searchData.mobile = '';
        //             this.searchData.contact = '';
        //         } else {
        //             this.$Message.error({
        //             background: true,
        //             content: "获取数据失败！",
        //             });
        //             this.loading = false;
        //         }
        //         })
        //         .catch((err) => {
        //         this.$Message.error({
        //             background: true,
        //             content: "获取数据失败！",
        //         });
        //         this.loading = false;
        //         });
        //     },



        // 获取用户详情接口
        getDetail(data){
            this.$get('/syaa/pc/sy/user/online/home/selectAuthUserHomeInfo',{
                memberId:data
            }).then(res=>{
                if(res.code == 200){
                    this.editRow = res.data
                    this.oldAddr = res.data.addr
                    this.modal.status = true
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        update(data){
            console.log(data)
            this.$post('/syaa/pc/sy/user/online/home/updateUserHome',{
                // ...data,
                familyMemberId:data.familyMemberId,
                familyId:data.familyId,
                name:data.name,
                userType:data.userType,
                contact:data.contact,
                houseCode:this.editRow.houseCode ? this.editRow.houseCode : ''
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.modal.status = false
                    this.page = this.currentPage
                    this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 字典接口
        getOption(data,model){
            this.$get('/old/api/common/sys/findDictByDictType',{
                dictType:data
            }).then(res=>{
                this[model] = res.dataList          
            })
        },
    },
    created(){
        this.getList()
        this.getOption('ONLINE_HOME_USER_TYPE','houseOptions')
    }
}
</script>

<style lang="less" scoped>
.edit{
    /deep/ #modal_contentarea{
        overflow: visible !important;
    }
}
.search{
   /deep/.ivu-form-item-content{
      margin-left: 0 !important;
    }
}
 
</style>